const colors = {
    purple: '#cd84f1',
    lightPurple: "#e6cbf5",
    lightPurple2: "#ebdff2",
    red: '#FF7675',
    wrongRed:'#e60000',
    yellow: '#FDCB6E',
    blue: '#1E90FF',
    grey: '#B2BEC3',
    darkGrey: '#606060',
    lightBlack: '#363636',
    orange: '#FFA500',
    black: '#000',
    white: '#fff',
    green: 'green',
    unFilledColor: "#f2f2f2",
    // correctGreen: "#228B22",
    lightGrey: "#D3D3D3",
    golden: "#FECE00",
    backGroundColor: '#1CA2D5',
    ghostWhite: '#F8F8FF',
    midGrey: '#E1EAF3',
    customGreen: '#228b22',
    nightBlue: '#0e1e40',
    lightRed: '#ffb3b3',
    darkModeDarkGray: '#575757',//,'#383838',
    darkModeLightGray: '#ababab',//,'#909090',
    darkModeMidGray: '#909090',//,'#696868',


    darkGeneralColor: '#2DEBC9',
    lightGeneralColor: '#adf7ea',
    backGroundColor: '#1F1D2B',
    lightGreen: '#adf7ea',
    generalColor: '#2DEBC9',
    generalPinkColor: '#8851A8',
    correctGreen: '#2DEBC9',
    companyColor: '#F6DC00',
    lightBlue: '#D4DEFF',
    dirtyWhite: '#E3E3E3',
    invalidRed: '#FF6768',
    formBorderColor: '#5B6481',
    yellow: '#FED000'
}

export { colors }
