
const convertGameList = (array) => {
    const convertedArray = array.map((mainItem, mainIndex) => {
      const innerArray = mainItem.map((item, index) => {
        return {
          //orjNumber: mainIndex != 0 ? item.correctNumber : item.orjNumber,
          orjNumber: item.orjNumber,
          correctNumber: item.correctNumber,
          changable: item.orjNumber === 0,
          wrongMark: false,
          noteNumbers: []
        };
      });
      return innerArray;
    });
    return convertedArray;
  }
  
  const setAfterNumberPress = (array, x, y, number, correctNumber) => {
    const newArray = array.map((mainItem, mainIndex) => {
      const innerArray = mainItem.map((item, index) => {
        if (mainIndex === y && index === x && item.changable) {
          item.orjNumber = number;
          item.wrongMark = correctNumber !== number;
          item.noteNumbers = [];
        }
        return item;
      });
      return innerArray;
    });
    return newArray;
  }
  
  const setAfterEraseAnswer = (array, x, y) => {
    const newArray = array.map((mainItem, mainIndex) => {
      const innerArray = mainItem.map((item, index) => {
        if (mainIndex === y && index === x) {
          item.orjNumber = 0;
          item.wrongMark = false;
          item.noteNumbers = [];
        }
        return item;
      });
      return innerArray;
    });
    return newArray;
  }
  
  const setAfterHintAnswer = (array, x, y) => {
    const newArray = array.map((mainItem, mainIndex) => {
      const innerArray = mainItem.map((item, index) => {
        if (mainIndex === y && index === x) {
          item.orjNumber = item.correctNumber;
          item.wrongMark = false;
          item.noteNumbers = [];
        }
        return item;
      });
      return innerArray;
    });
    return newArray;
  }
  
  const setNoteArray = (array, x, y, number) => {
    const newArray = array.map((mainItem, mainIndex) => {
      const innerArray = mainItem.map((item, index) => {
        if (mainIndex === y && index === x) {
          item.orjNumber = 0;
          item.noteNumbers = [...item.noteNumbers, number];
        }
        item.wrongMark = false;
        return item;
      });
      return innerArray;
    });
    return newArray;
  }
  
  const checkGameStatus = (array) => {
    let notExist = false;
    array.forEach(element => {
      element.forEach(element2 => {
        if (element2.orjNumber !== element2.correctNumber) {
          notExist = true;
        }
      });
    });
    return notExist;
  }
  
  const calcButtonsCount = (array) => {
    let counts = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    array.forEach(element => {
      element.forEach(element2 => {
        switch (element2.orjNumber) {
          case 1:
            counts[0] = counts[0] + 1;
            break;
          case 2:
            counts[1] = counts[1] + 1;
            break;
          case 3:
            counts[2] = counts[2] + 1;
            break;
          case 4:
            counts[3] = counts[3] + 1;
            break;
          case 5:
            counts[4] = counts[4] + 1;
            break;
          case 6:
            counts[5] = counts[5] + 1;
            break;
          case 7:
            counts[6] = counts[6] + 1;
            break;
          case 8:
            counts[7] = counts[7] + 1;
            break;
          case 9:
            counts[8] = counts[8] + 1;
            break;
          default:
  
        }
      });
    });
    return counts;
  }
  
  export default {
    convertGameList,
    setAfterNumberPress,
    setAfterEraseAnswer,
    setAfterHintAnswer,
    setNoteArray,
    checkGameStatus,
    calcButtonsCount
  }
  