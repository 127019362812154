const resources = {
    easy: 'Easy',
    medium: 'Medium',
    hard: 'Hard',
    erase: 'Erase',
    notes: 'Note',
    hint: 'Hint',
    newGame: 'New Game',
    gameOver: 'Game Over',
    congratulations: 'Congratulations!'
}

export { resources }