import { useEffect, useState } from 'react';
import './App.css';

import { easyList } from './models/EasyList';
import { mediumList } from './models/MediumList';
import { hardList } from './models/HardList';
import { resources } from './models/Resources';
import { colors } from './models/AppColors';
import gameService from './services/GameService';
import Utilities from './services/Utilities';

const SET_OPACITY = 0.5;
const SET_NOT_OPACITY = 1;
const SET_ZERO_OPACITY = 0;

function App() {
  const [activeItem, setActiveItem] = useState(resources.easy);
  // const [currentArray, setCurrentArray] = useState([]);
  // const [orgCurrentArray, setOrgCurrentArray] = useState([]);
  const [selectedIndex, setIndex] = useState({ x: 0, y: 0 });
  const [markNumber, setMarkNumber] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedIndexes, setSelectedIndex] = useState({ x: 0, y: 0 });
  const [sudokuArray, setArray] = useState([]);
  const [numberCounts, setNumberCounts] = useState([]);
  const [mistakeCount, setMistake] = useState(0);
  const [noteMode, setNoteMode] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [visits, setVisits] = useState(0);
  const [wingame, setWingame] = useState(false);

  useEffect(() => {
    let orjSudokuArray = gameService.getSudokuArray(activeItem);
    let convertedSudokuArray = Utilities.convertGameList(orjSudokuArray);
    setArray(JSON.parse(JSON.stringify(convertedSudokuArray)));
    setSelectedItem(convertedSudokuArray[0][0]);
    const numberCounts = Utilities.calcButtonsCount(orjSudokuArray);
    setNumberCounts(numberCounts);

    // setCurrentArray(easyList[0]);//todo change it with random
  }, []);

  const onPressNewGame = () => {
    setIndex({ x: 0, y: 0 });
    setMarkNumber(0);
    setSelectedIndex({ x: 0, y: 0 });
    setMistake(0);
    setNoteMode(false);
    setGameOver(false);
    const selectedBlockItem = sudokuArray[0][0];
    setSelectedItem(selectedBlockItem);
    setWingame(false);

    let orjSudokuArray = gameService.getSudokuArray(activeItem);
    let convertedSudokuArray = Utilities.convertGameList(orjSudokuArray);
    setArray(JSON.parse(JSON.stringify(convertedSudokuArray)));
    // setSelectedItem(convertedSudokuArray[0][0]);
    const numberCounts = Utilities.calcButtonsCount(orjSudokuArray);
    setNumberCounts(numberCounts);
  }

  const handleItemClick = (item) => {
    setActiveItem(item);

    let orjSudokuArray = gameService.getSudokuArray(item);
    let convertedSudokuArray = Utilities.convertGameList(orjSudokuArray);
    setArray(JSON.parse(JSON.stringify(convertedSudokuArray)));
    setSelectedItem(convertedSudokuArray[0][0]);
    const numberCounts = Utilities.calcButtonsCount(orjSudokuArray);
    setNumberCounts(numberCounts);


    // if (item === resources.easy) {
    //   setCurrentArray(easyList[index]);
    //   setOrgCurrentArray(easyList[index]);
    // }
    // else if (item === resources.medium) {
    //   setCurrentArray(mediumList[index]);
    //   setOrgCurrentArray(mediumList[index]);
    // }
    // else if (item === resources.hard) {
    //   setCurrentArray(hardList[index]);
    //   setOrgCurrentArray(hardList[index]);
    // }
  };

  const renderWebLevel = () => {
    return (
      <div className='level-web-container'>
        <div className={activeItem === resources.easy ? 'active-web-level' : 'inactive-web-level'}
          onClick={() => handleItemClick(resources.easy)}>
          {resources.easy} {activeItem === resources.easy ? <i className="fa fa-check-square"></i> : <i className="fa fa-square"></i>}
        </div>
        <div className={activeItem === resources.medium ? 'active-web-level' : 'inactive-web-level'}
          onClick={() => handleItemClick(resources.medium)}>
          {resources.medium} {activeItem === resources.medium ? <i className="fa fa-check-square"></i> : <i className="fa fa-square"></i>}
        </div>
        <div className={activeItem === resources.hard ? 'active-web-level' : 'inactive-web-level'}
          onClick={() => handleItemClick(resources.hard)}>
          {resources.hard} {activeItem === resources.hard ? <i className="fa fa-check-square"></i> : <i className="fa fa-square"></i>}
        </div>
      </div>
    )
  }

  const renderTabletLevel = () => {
    return (
      <div className='level-tablet-container'>
        <div className={activeItem === resources.easy ? 'active-tablet-level' : 'inactive-tablet-level'}
          onClick={() => handleItemClick(resources.easy)}>
          {resources.easy}
        </div>
        <div className={activeItem === resources.medium ? 'active-tablet-level' : 'inactive-tablet-level'}
          onClick={() => handleItemClick(resources.medium)}>
          {resources.medium}
        </div>
        <div className={activeItem === resources.hard ? 'active-tablet-level' : 'inactive-tablet-level'}
          onClick={() => handleItemClick(resources.hard)}>
          {resources.hard}
        </div>
      </div>
    )
  }

  const renderMobileLevel = () => {
    return (
      <div className='level-mobile-container'>
        <div className={activeItem === resources.easy ? 'active-mobile-level' : 'inactive-mobile-level'}
          onClick={() => handleItemClick(resources.easy)}>
          {resources.easy}
        </div>
        <div className={activeItem === resources.medium ? 'active-mobile-level' : 'inactive-mobile-level'}
          onClick={() => handleItemClick(resources.medium)}>
          {resources.medium}
        </div>
        <div className={activeItem === resources.hard ? 'active-mobile-level' : 'inactive-mobile-level'}
          onClick={() => handleItemClick(resources.hard)}>
          {resources.hard}
        </div>
      </div>
    )
  }

  const onClick = (x, y) => {
    setIndex({ x: x, y: y });
    const selectedBlockItem = sudokuArray[y][x];
    setSelectedItem(selectedBlockItem);
    setMarkNumber(selectedBlockItem.orjNumber);
    setSelectedIndex({ x, y });
  }

  const renderWebNoteNumbers = (item) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 1) ? 1 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 2) ? 2 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 3) ? 3 : ''}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 4) ? 4 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 5) ? 5 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 6) ? 6 : ''}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 7) ? 7 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 8) ? 8 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 9) ? 9 : ''}</span>
        </div>
      </div>
    )
  }

  const renderTabletNoteNumbers = (item) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 1) ? 1 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 2) ? 2 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 3) ? 3 : ''}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 4) ? 4 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 5) ? 5 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 6) ? 6 : ''}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 7) ? 7 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 8) ? 8 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 9) ? 9 : ''}</span>
        </div>
      </div>
    )
  }

  const renderMobileNoteNumbers = (item) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 1) ? 1 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 2) ? 2 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 3) ? 3 : ''}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 4) ? 4 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 5) ? 5 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 6) ? 6 : ''}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>{item.noteNumbers.find(n => n === 7) ? 7 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 8) ? 8 : ''}</span>
          <span>{item.noteNumbers.find(n => n === 9) ? 9 : ''}</span>
        </div>
      </div>
    )
  }

  const renderWebSudokuContainer = () => {
    return (
      <div className='sudoku-web-game-container'>
        <div className='sudoku-web-border'>
          {sudokuArray.map((row, rowIndex) => (
            <div key={`main_${rowIndex}`} className="sudoku-web-row">
              {row.map((cell, colIndex) => (
                <div onClick={() => onClick(colIndex, rowIndex)}
                  key={`sub_${rowIndex}${colIndex}`}
                  className='sudoku-web-cell'
                  style={{
                    borderRightWidth: (colIndex === 2 || colIndex === 5 || colIndex === 8) ? 2 : 0,
                    borderRightColor: (colIndex === 2 || colIndex === 5 || colIndex === 8) ? colors.black : colors.black,
                    borderBottomWidth: (rowIndex === 2 || rowIndex === 5 || rowIndex === 8) ? 2 : 0,
                    borderBottomColor: (rowIndex === 2 || rowIndex === 5 || rowIndex === 8) ? colors.black : colors.black,

                    backgroundColor: (selectedIndex.x === colIndex || selectedIndex.y == rowIndex)
                      ? (selectedIndex.x === colIndex && selectedIndex.y == rowIndex)
                        ? colors.lightGeneralColor
                        : (cell.orjNumber !== 0 && cell.orjNumber === markNumber)
                          ? colors.lightRed
                          : colors.darkModeMidGray
                      : cell.orjNumber !== 0 && cell.orjNumber === markNumber
                        ? colors.darkModeDarkGray
                        : colors.darkModeLightGray
                  }}>

                  {
                    cell.noteNumbers.length > 0
                      ? renderWebNoteNumbers(cell)
                      : <span style={{ fontWeight: '600', fontSize: 27, color: cell.wrongMark ? colors.wrongRed : colors.black }}>
                        {
                          cell.orjNumber != 0 ? cell.orjNumber : ''
                        }
                      </span>
                  }

                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderTabletSudokuContainer = () => {
    return (
      <div className='sudoku-tablet-game-container'>
        <div className='sudoku-tablet-border'>
          {sudokuArray.map((row, rowIndex) => (
            <div key={`main_${rowIndex}`} className="sudoku-tablet-row">
              {row.map((cell, colIndex) => (
                <div onClick={() => onClick(colIndex, rowIndex)}
                  key={`sub_${rowIndex}${colIndex}`}
                  className='sudoku-tablet-cell'
                  style={{
                    borderRightWidth: (colIndex === 2 || colIndex === 5 || colIndex === 8) ? 2 : 0,
                    borderRightColor: (colIndex === 2 || colIndex === 5 || colIndex === 8) ? colors.black : colors.black,
                    borderBottomWidth: (rowIndex === 2 || rowIndex === 5 || rowIndex === 8) ? 2 : 0,
                    borderBottomColor: (rowIndex === 2 || rowIndex === 5 || rowIndex === 8) ? colors.black : colors.black,

                    backgroundColor: (selectedIndex.x === colIndex || selectedIndex.y == rowIndex)
                      ? (selectedIndex.x === colIndex && selectedIndex.y == rowIndex)
                        ? colors.lightGeneralColor
                        : (cell.orjNumber !== 0 && cell.orjNumber === markNumber)
                          ? colors.lightRed
                          : colors.darkModeMidGray
                      : cell.orjNumber !== 0 && cell.orjNumber === markNumber
                        ? colors.darkModeDarkGray
                        : colors.darkModeLightGray
                  }}>

                  {
                    cell.noteNumbers.length > 0
                      ? renderTabletNoteNumbers(cell)
                      : <span style={{ fontWeight: '600', fontSize: 27, color: cell.wrongMark ? colors.wrongRed : colors.black }}>
                        {
                          cell.orjNumber != 0 ? cell.orjNumber : ''
                        }
                      </span>
                  }

                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderMobileSudokuContainer = () => {
    return (
      <div className='sudoku-mobile-game-container'>
        <div className='sudoku-mobile-border'>
          {sudokuArray.map((row, rowIndex) => (
            <div key={`main_${rowIndex}`} className="sudoku-mobile-row">
              {row.map((cell, colIndex) => (
                <div onClick={() => onClick(colIndex, rowIndex)}
                  key={`sub_${rowIndex}${colIndex}`}
                  className='sudoku-mobile-cell'
                  style={{
                    borderRightWidth: (colIndex === 2 || colIndex === 5 || colIndex === 8) ? 2 : 0,
                    borderRightColor: (colIndex === 2 || colIndex === 5 || colIndex === 8) ? colors.black : colors.black,
                    borderBottomWidth: (rowIndex === 2 || rowIndex === 5 || rowIndex === 8) ? 2 : 0,
                    borderBottomColor: (rowIndex === 2 || rowIndex === 5 || rowIndex === 8) ? colors.black : colors.black,

                    backgroundColor: (selectedIndex.x === colIndex || selectedIndex.y == rowIndex)
                      ? (selectedIndex.x === colIndex && selectedIndex.y == rowIndex)
                        ? colors.lightGeneralColor
                        : (cell.orjNumber !== 0 && cell.orjNumber === markNumber)
                          ? colors.lightRed
                          : colors.darkModeMidGray
                      : cell.orjNumber !== 0 && cell.orjNumber === markNumber
                        ? colors.darkModeDarkGray
                        : colors.darkModeLightGray
                  }}>

                  {
                    cell.noteNumbers.length > 0
                      ? renderMobileNoteNumbers(cell)
                      : <span style={{ fontWeight: '600', fontSize: 27, color: cell.wrongMark ? colors.wrongRed : colors.black }}>
                        {
                          cell.orjNumber != 0 ? cell.orjNumber : ''
                        }
                      </span>
                  }

                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const onPressNumber = (number, disabled) => {

    if (disabled)
      return;

    if (mistakeCount === 3 || mistakeCount > 3)// if game over do not anything
      return;

    if (!noteMode) {
      setMarkNumber(number);

      const newArray = Utilities.setAfterNumberPress(sudokuArray, selectedIndexes.x, selectedIndexes.y, number, selectedItem.correctNumber);
      setArray(newArray);

      if (selectedItem.correctNumber !== number) {
        const newCount = mistakeCount + 1;
        if (newCount == 3) {
          setMistake(newCount);
          setTimeout(() => { setGameOver(true); }, 1000)
        } else if (newCount < 3) {
          setMistake(newCount);
        }
      }

      if (selectedItem.correctNumber === number) {
        const numberCounts = Utilities.calcButtonsCount(sudokuArray);
        setNumberCounts(numberCounts);
      }

      const winGame = Utilities.checkGameStatus(newArray);
      if (!winGame) {
        setWingame(true);
      }
    } else {
      setMarkNumber(0);
      const newArray = Utilities.setNoteArray(sudokuArray, selectedIndexes.x, selectedIndexes.y, number);
      setArray(newArray);
    }
  }

  const renderWebNumbersContainer = () => {
    return (
      <div className='numbers-web-main-container'>
        <div className='numbers-web-container'>
          <div onClick={() => onPressNumber(1, (!selectedItem.changable || numberCounts[0] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[0] !== 9) ? SET_OPACITY : numberCounts[0] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>1</div>
          <div onClick={() => onPressNumber(2, (!selectedItem.changable || numberCounts[1] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[1] !== 9) ? SET_OPACITY : numberCounts[1] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>2</div>
          <div onClick={() => onPressNumber(3, (!selectedItem.changable || numberCounts[2] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[2] !== 9) ? SET_OPACITY : numberCounts[2] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>3</div>
          <div onClick={() => onPressNumber(4, (!selectedItem.changable || numberCounts[3] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[3] !== 9) ? SET_OPACITY : numberCounts[3] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>4</div>
          <div onClick={() => onPressNumber(5, (!selectedItem.changable || numberCounts[4] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[4] !== 9) ? SET_OPACITY : numberCounts[4] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>5</div>
          <div onClick={() => onPressNumber(6, (!selectedItem.changable || numberCounts[5] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[5] !== 9) ? SET_OPACITY : numberCounts[5] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>6</div>
          <div onClick={() => onPressNumber(7, (!selectedItem.changable || numberCounts[6] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[6] !== 9) ? SET_OPACITY : numberCounts[6] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>7</div>
          <div onClick={() => onPressNumber(8, (!selectedItem.changable || numberCounts[7] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[7] !== 9) ? SET_OPACITY : numberCounts[7] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>8</div>
          <div onClick={() => onPressNumber(9, (!selectedItem.changable || numberCounts[8] === 9))} className='number-web-container' style={{ opacity: (!selectedItem.changable && numberCounts[8] !== 9) ? SET_OPACITY : numberCounts[8] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>9</div>
        </div>
      </div>
    )
  }

  const renderTabletNumbersContainer = () => {
    return (
      <div className='numbers-tablet-main-container'>
        <div className='numbers-tablet-container'>
          <div onClick={() => onPressNumber(1, (!selectedItem.changable || numberCounts[0] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[0] !== 9) ? SET_OPACITY : numberCounts[0] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>1</div>
          <div onClick={() => onPressNumber(2, (!selectedItem.changable || numberCounts[1] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[1] !== 9) ? SET_OPACITY : numberCounts[1] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>2</div>
          <div onClick={() => onPressNumber(3, (!selectedItem.changable || numberCounts[2] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[2] !== 9) ? SET_OPACITY : numberCounts[2] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>3</div>
          <div onClick={() => onPressNumber(4, (!selectedItem.changable || numberCounts[3] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[3] !== 9) ? SET_OPACITY : numberCounts[3] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>4</div>
          <div onClick={() => onPressNumber(5, (!selectedItem.changable || numberCounts[4] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[4] !== 9) ? SET_OPACITY : numberCounts[4] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>5</div>
          <div onClick={() => onPressNumber(6, (!selectedItem.changable || numberCounts[5] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[5] !== 9) ? SET_OPACITY : numberCounts[5] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>6</div>
          <div onClick={() => onPressNumber(7, (!selectedItem.changable || numberCounts[6] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[6] !== 9) ? SET_OPACITY : numberCounts[6] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>7</div>
          <div onClick={() => onPressNumber(8, (!selectedItem.changable || numberCounts[7] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[7] !== 9) ? SET_OPACITY : numberCounts[7] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>8</div>
          <div onClick={() => onPressNumber(9, (!selectedItem.changable || numberCounts[8] === 9))} className='number-tablet-container' style={{ opacity: (!selectedItem.changable && numberCounts[8] !== 9) ? SET_OPACITY : numberCounts[8] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>9</div>
        </div>
      </div>
    )
  }

  const renderMobileNumbersContainer = () => {
    return (
      <div className='numbers-mobile-main-container'>
        <div className='numbers-mobile-container'>
          <div onClick={() => onPressNumber(1, (!selectedItem.changable || numberCounts[0] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[0] !== 9) ? SET_OPACITY : numberCounts[0] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>1</div>
          <div onClick={() => onPressNumber(2, (!selectedItem.changable || numberCounts[1] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[1] !== 9) ? SET_OPACITY : numberCounts[1] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>2</div>
          <div onClick={() => onPressNumber(3, (!selectedItem.changable || numberCounts[2] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[2] !== 9) ? SET_OPACITY : numberCounts[2] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>3</div>
          <div onClick={() => onPressNumber(4, (!selectedItem.changable || numberCounts[3] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[3] !== 9) ? SET_OPACITY : numberCounts[3] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>4</div>
          <div onClick={() => onPressNumber(5, (!selectedItem.changable || numberCounts[4] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[4] !== 9) ? SET_OPACITY : numberCounts[4] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>5</div>
          <div onClick={() => onPressNumber(6, (!selectedItem.changable || numberCounts[5] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[5] !== 9) ? SET_OPACITY : numberCounts[5] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>6</div>
          <div onClick={() => onPressNumber(7, (!selectedItem.changable || numberCounts[6] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[6] !== 9) ? SET_OPACITY : numberCounts[6] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>7</div>
          <div onClick={() => onPressNumber(8, (!selectedItem.changable || numberCounts[7] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[7] !== 9) ? SET_OPACITY : numberCounts[7] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>8</div>
          <div onClick={() => onPressNumber(9, (!selectedItem.changable || numberCounts[8] === 9))} className='number-mobile-container' style={{ opacity: (!selectedItem.changable && numberCounts[8] !== 9) ? SET_OPACITY : numberCounts[8] === 9 ? SET_ZERO_OPACITY : SET_NOT_OPACITY }}>9</div>
        </div>
      </div>
    )
  }

  const onPressErase = () => {
    if (!selectedItem.changable)
      return;

    const newArray = Utilities.setAfterEraseAnswer(sudokuArray, selectedIndexes.x, selectedIndexes.y);
    setMarkNumber(0);
    setArray(newArray);
  }

  const onPressHint = () => {
    setNoteMode(false);
    setTimeout(() => {
      const newArray = Utilities.setAfterHintAnswer(sudokuArray, selectedIndexes.x, selectedIndexes.y);
      setMarkNumber(sudokuArray[selectedIndexes.y][selectedIndexes.x].correctNumber);
      setArray(newArray);
    }, 10);

    alert("We will fill the number automatically");
    // if (interstitial) {
    //     interstitial.show()
    // }
  }

  const renderWebExtraButtonsContainer = () => {
    return (
      <div className='extra-buttons-main-container'>
        <div className='extra-buttons-container'>
          <div className='extra-button-container' onClick={() => { onPressErase() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-eraser extra-button-web-icon"></i>
            <span className='extra-button-web-text'>{resources.erase}</span>
          </div>
          <div className='extra-button-container' onClick={() => setNoteMode(!noteMode)} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY, borderColor: noteMode ? colors.green : colors.darkGeneralColor }}>
            <i className="fa fa-edit extra-button-web-icon" style={{ color: noteMode ? colors.green : colors.white }}></i>
            <span className='extra-button-web-text' style={{ color: noteMode ? colors.green : colors.white }}>{resources.notes}</span>
          </div>
          <div className='extra-button-container' onClick={() => { onPressHint() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-lightbulb-o extra-button-web-icon"></i>
            <span className='extra-button-web-text'>{resources.hint}</span>
          </div>
          <div className='extra-button-container' onClick={() => { onPressNewGame() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-gamepad extra-button-web-icon"></i>
            <span className='extra-button-web-text'>{resources.newGame}</span>
          </div>
        </div>
      </div>
    )
  }

  const renderTabletExtraButtonsContainer = () => {
    return (
      <div className='extra-buttons-main-container'>
        <div className='extra-buttons-container'>
          <div className='extra-button-container' onClick={() => { onPressErase() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-eraser extra-button-tablet-icon"></i>
            <span className='extra-button-tablet-text'>{resources.erase}</span>
          </div>
          <div className='extra-button-container' onClick={() => setNoteMode(!noteMode)} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY, borderColor: noteMode ? colors.green : colors.darkGeneralColor }}>
            <i className="fa fa-edit extra-button-tablet-icon" style={{ color: noteMode ? colors.green : colors.white }}></i>
            <span className='extra-button-tablet-text' style={{ color: noteMode ? colors.green : colors.white }}>{resources.notes}</span>
          </div>
          <div className='extra-button-container' onClick={() => { onPressHint() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-lightbulb-o extra-button-tablet-icon"></i>
            <span className='extra-button-tablet-text'>{resources.hint}</span>
          </div>
          <div className='extra-button-container' onClick={() => { onPressNewGame() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-gamepad extra-button-tablet-icon"></i>
            <span className='extra-button-tablet-text'>{resources.newGame}</span>
          </div>
        </div>
      </div>
    )
  }

  const renderMobileExtraButtonsContainer = () => {
    return (
      <div className='extra-buttons-main-container'>
        <div className='extra-buttons-container'>
          <div className='extra-button-container' onClick={() => { onPressErase() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-eraser extra-button-mobile-icon"></i>
            <span className='extra-button-mobile-text'>{resources.erase}</span>
          </div>
          <div className='extra-button-container' onClick={() => setNoteMode(!noteMode)} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY, borderColor: noteMode ? colors.green : colors.darkGeneralColor }}>
            <i className="fa fa-edit extra-button-mobile-icon" style={{ color: noteMode ? colors.green : colors.white }}></i>
            <span className='extra-button-mobile-text' style={{ color: noteMode ? colors.green : colors.white }}>{resources.notes}</span>
          </div>
          <div className='extra-button-container' onClick={() => { onPressHint() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-lightbulb-o extra-button-mobile-icon"></i>
            <span className='extra-button-mobile-text'>{resources.hint}</span>
          </div>
          <div className='extra-button-container' onClick={() => { onPressNewGame() }} style={{ opacity: !selectedItem.changable ? SET_OPACITY : SET_NOT_OPACITY }}>
            <i className="fa fa-gamepad extra-button-mobile-icon"></i>
            <span className='extra-button-mobile-text'>{resources.newGame}</span>
          </div>
        </div>
      </div>
    )
  }

  const renderWebGameOver = () => {
    return (
      <div className='game-over-web-container'>
        <span style={{ color: colors.white, fontSize: 40, fontWeight: 600 }}>{resources.gameOver}</span>
        <button className='gameover-button-container' style={{}} onClick={() => { setGameOver(false); onPressNewGame(); }}>{resources.newGame}</button>
      </div>
    )
  }

  const renderWebGame = () => {
    return (
      <>
        {renderWebLevel()}

        {renderWebSudokuContainer()}

        {renderWebNumbersContainer()}

        {renderWebExtraButtonsContainer()}
      </>
    )
  }

  const renderTabletGame = () => {
    return (
      <>
        {renderTabletLevel()}

        {renderTabletSudokuContainer()}

        {renderTabletNumbersContainer()}

        {renderTabletExtraButtonsContainer()}
      </>
    )
  }

  const renderTabletGameOver = () => {
    return (
      <div className='game-over-tablet-container'>
        <span style={{ color: colors.white, fontSize: 40, fontWeight: 600 }}>{resources.gameOver}</span>
        <button className='gameover-button-container' style={{}} onClick={() => { setGameOver(false); onPressNewGame(); }}>{resources.newGame}</button>
      </div>
    )
  }

  const renderMobileGame = () => {
    return (
      <>
        {renderMobileLevel()}

        {renderMobileSudokuContainer()}

        {renderMobileNumbersContainer()}

        {renderMobileExtraButtonsContainer()}
      </>
    )
  }

  const renderMobileGameOver = () => {
    return (
      <div className='game-over-mobile-container'>
        <span style={{ color: colors.white, fontSize: 40, fontWeight: 600 }}>{resources.gameOver}</span>
        <button className='gameover-button-container' style={{}} onClick={() => { setGameOver(false); onPressNewGame(); }}>{resources.newGame}</button>
      </div>
    )
  }

  const renderWebWingame = () => {
    return (
      <div className='win-game-web-container'>
        <span style={{ fontWeight: 600, fontSize: 45, color: colors.white }}>{resources.congratulations}</span>
        <dotlottie-player src="https://lottie.host/5ece1fce-45ab-4b72-9d21-d1bbeba6909b/wcGQkZnSX0.json" background="transparent" speed="1" style={{ width: 300, height: 300 }} s loop autoplay></dotlottie-player>
        <button className='wingame-button-container' style={{}} onClick={() => { onPressNewGame(); setWingame(false); }}>{resources.newGame}</button>
      </div>
    )
  }

  const renderTabletWingame = () => {
    return (
      <div className='win-game-tablet-container'>
        <span style={{ fontWeight: 600, fontSize: 45, color: colors.white }}>{resources.congratulations}</span>
        <dotlottie-player src="https://lottie.host/5ece1fce-45ab-4b72-9d21-d1bbeba6909b/wcGQkZnSX0.json" background="transparent" speed="1" style={{ width: 300, height: 300 }} s loop autoplay></dotlottie-player>
        <button className='wingame-button-container' style={{}} onClick={() => { onPressNewGame(); setWingame(false); }}>{resources.newGame}</button>
      </div>
    )
  }

  const renderMobileWingame = () => {
    return (
      <div className='win-game-mobile-container'>
        <span style={{ fontWeight: 600, fontSize: 45, color: colors.white }}>{resources.congratulations}</span>
        <dotlottie-player src="https://lottie.host/5ece1fce-45ab-4b72-9d21-d1bbeba6909b/wcGQkZnSX0.json" background="transparent" speed="1" style={{ width: 200, height: 200 }} s loop autoplay></dotlottie-player>
        <button className='wingame-button-container' style={{}} onClick={() => { onPressNewGame(); setWingame(false); }}>{resources.newGame}</button>
      </div>
    )
  }

  const renderWeb = () => {
    return (
      <div className='web-container'>
        <div className='left-web-container'>

        </div>

        <div className='game-web-container'>

          {gameOver
            ? renderWebGameOver()
            : wingame ?
              renderWebWingame()
              : renderWebGame()}

        </div>

        <div className='right-web-container'>

        </div>

      </div>
    )
  }

  const renderTablet = () => {
    return (
      <div className='tablet-container'>

        {gameOver
          ? renderTabletGameOver()
          : wingame ?
            renderTabletWingame()
            : renderTabletGame()}

      </div>
    )
  }

  const renderMobile = () => {
    return (
      <div className='mobile-container'>
        {gameOver
          ? renderMobileGameOver()
          : wingame ?
            renderMobileWingame()
            : renderMobileGame()}
      </div>
    )
  }

  const renderParagraphs = () => {
    return (
      <>
        <h1 style={{ color: colors.white }}>Play Free Sudoku</h1>
        <p style={{ color: colors.white }}>Welcome to our engaging online free Sudoku platform, where puzzle enthusiasts and casual players alike can enjoy the timeless challenge for free. Sudoku is a popular logic-based number-placement puzzle that has captivated minds around the world. The objective is simple: fill a 9x9 grid with digits so that each column, each row, and each of the nine 3x3 subgrids (called 'regions') contains all of the digits from 1 to 9.</p>
        <h2 style={{ color: colors.white }}>How to play Sudoku game?</h2>
        <p style={{ color: colors.white }}>Our user-friendly interface provides a seamless and enjoyable gaming experience. Whether you're a seasoned pro or a beginner looking to sharpen your logical skills, our platform offers a variety of difficulty levels to suit every player. Dive into the world of players on our website, where mental agility meets entertainment. Challenge yourself, track your progress, and enjoy the satisfaction of completing these intellectually stimulating puzzles. Start playing now and embark on a journey of fun and mental exercise!</p>
        <h3 style={{ color: colors.white }}>About Sudoku</h3>
        <p style={{ color: colors.white }}>Welcome to the fascinating world of Sudoku! It is a popular number puzzle game that challenges players to fill a 9x9 grid with numbers so that each row, each column, and each of the nine 3x3 subgrids, known as regions, contain all of the digits from 1 to 9. Originating from Japan, it has gained worldwide acclaim for its captivating blend of logic, strategy, and pattern recognition. It's a game that transcends age and skill levels, providing a stimulating mental workout while fostering a sense of accomplishment as players unlock the grid's secrets. Whether you're a seasoned enthusiast or a newcomer eager to embark on this puzzle-solving adventure, our website offers a platform for you to enjoy and master the art of it. Get ready to sharpen your mind and have fun with the ultimate numbers game! <a href='https://en.wikipedia.org/wiki/Sudoku' target='_blank'>Fore more detail visit Wikipedia</a> </p>
        <h4 style={{ color: colors.white }}>Who Can Play Sudoku: A Brainteaser for All Ages and Skill Levels</h4>
        <p style={{ color: colors.white }}>Sudoku, the globally beloved number puzzle, is a game that transcends age and skill boundaries. Whether you're a seasoned puzzle enthusiast or a newcomer to the world of brainteasers, it offers a captivating challenge for everyone. Its simple rules and logical gameplay make it accessible to individuals of all ages, from youngsters honing their critical thinking skills to seniors enjoying a mentally stimulating pastime. With varying difficulty levels, Sudoku caters to both beginners and experienced players, ensuring an engaging experience for anyone ready to embark on a journey of numbers and strategy. Discover the joy of the game – a game that truly welcomes all to exercise their minds and have fun in the process!</p>
        <h5 style={{ color: colors.white }}>Mastering Sudoku: Essential Tips for Solving Puzzles with Precision</h5>
        <p style={{ color: colors.white }}>Unlock the secrets to becoming a Sudoku virtuoso with our comprehensive guide to mastering the art of puzzle-solving. Whether you're a novice or a seasoned player looking to sharpen your skills, these game tips are designed to elevate your game and enhance your logical reasoning. From strategic number placement to effective elimination techniques, we'll delve into the fundamentals that form the backbone of successful game solving. Discover how to approach different difficulty levels, tackle challenging patterns, and employ advanced strategies that will empower you to conquer even the most perplexing puzzles. Embrace these tips as your trusted companions on the journey to puzzle-solving proficiency, and watch as your confidence and enjoyment soar to new heights. <a href='https://en.wikipedia.org/wiki/Sudoku_solving_algorithms' target='_blank'>Fore more detail about the tips visit Wikipedia</a></p>
      </>
    )
  }

  const renderText = () => {
    return (
      <div className='text-main-container'>
        <div className='text-container'>
          {renderParagraphs()}
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderWeb()}
      {renderTablet()}
      {renderMobile()}
      <div>
        {renderText()}
      </div>
    </div>
  );
}

export default App;
