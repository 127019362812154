import { easyList } from '../models/EasyList';
import { mediumList } from '../models/MediumList';
import { hardList } from '../models/HardList';
import { resources } from '../models/Resources';

const getSudokuArray = (type) => {
  switch (type) {
    case resources.easy:
      return getRandom(easyList);
    case resources.medium:
      return getRandom(mediumList);
    case resources.hard:
      return getRandom(hardList);
    default:
      return getRandom(easyList);
  }
}

const getRandom = (array) => {
  return array[Math.floor(Math.random() * array.length)];
  //return array[array.length-1];
}

export default {
  getSudokuArray
}
